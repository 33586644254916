import * as React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import textureClusterNamesQuery from '@graphql/queries/textureClusterNamesQuery';
import CreateOutlined from '@mui/icons-material/CreateOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  FormControlLabel,
  Switch,
  Typography,
  Tooltip,
  useTheme,
  IconButton,
  Box,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import EditCutomTextureClusterName from './EditCustomTextureClusterName';
import { TextureCluster } from './textureClusterUtils';
import useStyles from './useStyles';
import LoadingScreen from '../../../../components/LoadingScreen';
import Modal from '../../../../components/Modal/PopUp';
import useCustomTextureName from '../../../../hooks/useCustomTextureName';
import { truncateText } from '../ReportTextureComposition/utils';

interface Props {
  reportId: string;
  textureClusters: TextureCluster[];
  useTextureClusterName?: boolean;
  setUseTextureClusterName?: React.Dispatch<React.SetStateAction<boolean>>;
  highestClusterScoreId?: number;
}

const ReportTextureCluster: React.FC<Props> = (props) => {
  const {
    reportId,
    textureClusters,
    useTextureClusterName,
    setUseTextureClusterName,
    highestClusterScoreId,
  } = props;

  const { t } = useTranslation();

  const classes = useStyles(useTheme());
  const { updateTextureCustomName, isLoading } = useCustomTextureName(reportId);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [textureClusterNameData, setTextureClusterNameData] =
    useState<reports.TCNameData>();
  const [currentHoveredRow, setCurrentHoveredRow] = useState<number>();

  const {
    data: tcNameData,
    loading: tcNameLoading,
    error: tcNameError,
  } = useQuery<reports.ReportTextureClusterNamesResponse>(
    textureClusterNamesQuery,
    {
      variables: {
        reportID: reportId,
      },
    },
  );

  const getTCNameByIndex = (index: number) => {
    const clusterData = tcNameData?.allRpTextureClusterNames?.nodes?.find(
      (x) => x.clusterIdx === index,
    ) ?? null;
    return clusterData && clusterData.customClusterName && clusterData.customClusterName.length > 0
      ? truncateText(clusterData.customClusterName)
      : clusterData?.clusterName ?? '';
  };

  const getTCDataByIndex = (index: number) =>
    tcNameData.allRpTextureClusterNames.nodes.find(
      (x) => x.clusterIdx === index,
    );

  const EditButton = withStyles({
    root: {
      '& .show-on-hover': {
        display: 'none',
      },
      '&:hover .show-on-hover': {
        display: 'flex',
      },
    },
  })(IconButton);

  if (tcNameLoading || tcNameError) {
    return <div />;
  }

  return (
    <div>
      <Grid container spacing={1} alignItems={'center'} direction={'row'}>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              margin: '1rem',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5">
              {t('reports.texturesSummary.title')}
            </Typography>
            <Tooltip title={t('reports.texturesSummary.clusterExplain')}>
              <IconButton size="large">
                <InfoOutlinedIcon style={{ fontSize: 24, color: '#777' }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        {tcNameData?.allRpTextureClusterNames?.nodes?.length > 0 &&
          setUseTextureClusterName && (
            <Grid item xs={6}>
              <Tooltip
                title={t('reports.texturesSummary.useClusterNamesToolTip')}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={useTextureClusterName}
                      onChange={(e) =>
                        setUseTextureClusterName(e.target.checked)
                      }
                      name="isClusterName"
                    />
                  }
                  label={
                    <Typography variant="caption">
                      {t('reports.texturesSummary.useClusterNamesLabel')}
                    </Typography>
                  }
                />
              </Tooltip>
            </Grid>
          )}
      </Grid>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('reports.texturesSummary.cluster')}</TableCell>
            {useTextureClusterName && (
              <TableCell>{t('reports.texturesSummary.name')}</TableCell>
            )}
            <TableCell>{t('reports.texturesSummary.textures')}</TableCell>
            <TableCell align="right" variant="footer"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {textureClusters.map((row, rowIdx) => {
            const rowKey = Object.keys(row)[0];
            return (
              <TableRow key={'texturessummary_' + rowIdx}>
                <TableCell
                  className={
                    highestClusterScoreId == Number(rowIdx + 1)
                      ? classes.highestScore
                      : ''
                  }
                >
                  {rowKey}
                </TableCell>
                {useTextureClusterName && (
                  <>
                    <TableCell
                      onMouseEnter={() => {
                        setCurrentHoveredRow(rowIdx + 1);
                        setShowEdit(true);
                      }}
                    >
                      {getTCNameByIndex(rowIdx + 1) ?? ''}
                      <EditButton
                        style={{
                          visibility:
                            showEdit && rowIdx + 1 === currentHoveredRow
                              ? 'visible'
                              : 'hidden',
                        }}
                        size="small"
                        onClick={() => {
                          setTextureClusterNameData(
                            getTCDataByIndex(rowIdx + 1),
                          );
                          setOpenModal(true);
                          setShowEdit(false);
                        }}
                        onMouseEnter={() => {
                          setCurrentHoveredRow(rowIdx + 1);
                          setShowEdit(true);
                        }}
                        onMouseLeave={() => setShowEdit(false)}
                      >
                        <CreateOutlined color="primary" fontSize="small" />
                      </EditButton>
                    </TableCell>
                  </>
                )}
                <TableCell
                  className={
                    highestClusterScoreId == Number(rowIdx + 1)
                      ? classes.highestScore
                      : ''
                  }
                  align="left"
                  colSpan={2}
                >
                  {row[rowKey].map((item, itemIdx) => (
                    <Tooltip
                      key={'texturessummary_' + rowIdx + '-' + itemIdx}
                      title={`${t('reports.texturesSummary.confidence')}: ${
                        item.confidence
                      }`}
                    >
                      <span>
                        {item.texture}
                        {itemIdx == row[rowKey].length - 1 ? '' : ', '}
                      </span>
                    </Tooltip>
                  ))}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          margin: '1rem',
          alignItems: 'center',
        }}
      >
        <InfoOutlinedIcon
          sx={{ fontSize: 16, color: 'palette.primary.main' }}
        />
        <Typography margin={2} variant="body2">
          {t('reports.texturesSummary.boldExplainer')}
        </Typography>
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={t('reports.texturesSummary.editCustomTextureClusterNameTitle')}
      >
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <EditCutomTextureClusterName
            closeModal={() => setOpenModal(false)}
            textureClusterNameData={textureClusterNameData}
            updateTextureCustomName={async (id, customName) =>
              await updateTextureCustomName(id, customName).finally(() =>
                setOpenModal(false),
              )
            }
          />
        )}
      </Modal>
    </div>
  );
};

export default ReportTextureCluster;
