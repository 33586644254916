import { useState, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { ROLE_ENUM, NOTIFICATION_TYPE_ENUM } from '../constants/enum';
import graphqlClient from '../consumers/graphqlClient';
import CreateNotificationMutation from '../graphql/mutations/CreateNotification';
import CreateNotificationTypeMutation from '../graphql/mutations/CreateNotificationType';
import DeleteNotificationMessageMutation from '../graphql/mutations/DeleteNotification';
import UpdateNotificationMutation from '../graphql/mutations/UpdateNotification';
import UserNotificationsQuery from '../graphql/queries/UserNotificationsQuery';
import selectViewerUserId from '../selectors/viewerUserId';
import selectWorkspaceProducerId from '../selectors/workspaceProducerId';

const useMessageNotification = () => {
  const userId = useSelector((state) => selectViewerUserId(state));
  const producerId = useSelector((state) => selectWorkspaceProducerId(state));
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createNotification = useCallback(async (message: string) => {
    setIsLoading(true);
    const {
      data: {
        createNotificationType: {
          notificationType: { id: notificationTypeId },
        },
      },
    } = await graphqlClient.mutate({
      mutation: CreateNotificationTypeMutation,
      variables: {
        notificationType: {
          panelId: null,
          role: ROLE_ENUM.GASTROGRAPH_USER,
          notificationType: NOTIFICATION_TYPE_ENUM.CHANGE_LOG,
          sentOn: new Date().toISOString(),
        },
      },
    });

    await graphqlClient
      .mutate({
        mutation: CreateNotificationMutation,
        variables: {
          notification: {
            userId,
            producerId,
            active: true,
            notificationTypeId: notificationTypeId,
            message,
          },
        },
        refetchQueries: [
          {
            query: UserNotificationsQuery,
            variables: {
              producerId,
              active: true,
            },
          },
        ],
      })
      .finally(() => setIsLoading(false));
  }, []);

  const updateNotification = useCallback(
    async (id: number, message: string) => {
      setIsLoading(true);
      await graphqlClient
        .mutate({
          mutation: UpdateNotificationMutation,
          variables: {
            id,
            patch: {
              message,
            },
          },
          refetchQueries: [
            {
              query: UserNotificationsQuery,
              variables: {
                producerId,
                active: true,
              },
            },
          ],
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  const deleteNotification = useCallback(async (id) => {
    setIsLoading(true);
    await graphqlClient
      .mutate({
        mutation: DeleteNotificationMessageMutation,
        variables: {
          input: {
            id,
          },
        },
        refetchQueries: [
          {
            query: UserNotificationsQuery,
            variables: {
              producerId,
              active: true,
            },
          },
        ],
      })
      .finally(() => setIsLoading(false));
  }, []);

  return {
    createNotification,
    updateNotification,
    deleteNotification,
    isLoading,
  };
};

export default useMessageNotification;
