import {
  CustomerPreferences,
  getLabelArray,
  getVectorLength,
} from 'services/customerPreferences';

import { ProductVersionSet, ProductVersion } from '../ProductVersion';

const numbers = Array.from({ length: 200 }, (_, i) => (i + 1).toString());

/**
 * Determine label for versioned productId
 *
 * @param customerPreferences customerPreference object
 * @param productIds array of underlying product ids on the map
 * @param productId specific productId looking to get labeled
 * @returns label for specific productId
 */
export const mapLabelVersion = (
  customerPreferences: CustomerPreferences,
  productKeys: ProductVersionSet,
  productKey: ProductVersion,
  offset?: number,
): string => {
  offset = offset ?? 0;
  if (typeof productKey === 'undefined' || productKey === null) {
    return '?';
  }

  const labelArray = getLabelArray(customerPreferences);

  if (productKeys.size + offset > labelArray.length) {
    return numbers[productKeys.findIndex(productKey) + offset];
  } else {
    return labelArray[productKeys.findIndex(productKey) + offset];
  }
};

export const simpleProductTooltip = (
  productName: string,
  productLabel: string,
): string => {
  return (
    '<div class="prodtext">' +
    productName +
    ' (' +
    productLabel +
    ')' +
    '</div>'
  );
};

/**
 * Tooltip to show when hovering over a product label on the map
 *
 * @param visibleProducts
 * @param productName
 * @param productPq
 * @param meanPq
 * @param colors
 * @returns
 */
export const productTooltip = (
  productsToShow: {
    productName: string;
    productPq: number;
    productLabel: string;
  }[],
  meanPq: number,
  colors: d3.ScaleLinear<string, string, never>,
): string => {
  return productsToShow
    .map((p) => {
      if (typeof p.productPq === 'undefined' || p.productPq === null) {
        return (
          '<div class="prodtext">' +
          p.productName +
          ' (' +
          p.productLabel +
          ')' +
          '</div>'
        );
      } else {
        return (
          '<div class="prodtext">' +
          p.productName +
          ' (' +
          p.productLabel +
          ')' +
          '</div>' +
          '<div class="pq">' +
          '<div class="pqtext">' +
          p.productPq.toFixed(2) +
          '</div>' +
          '<div class="pqcircle" style="background-color: ' +
          colors(p.productPq - meanPq) +
          ';"> </div>' +
          '</div>'
        );
      }
    })
    .join('');
};

/**
 * Tooltip to show when hovering over a vector label
 *
 * @param customerPreferences customerPreference object
 * @param  vectorName
 * @param  vectorLength
 * @param  zoom
 * @returns
 */
export const vectorTooltip = (
  customerPreferences: CustomerPreferences,
  vectorName: string,
  vectorLength: number,
  zoom: number,
): string => {
  return (
    '<div class="prodtext">' +
    vectorName +
    '</div>' +
    '<div class="pq">' +
    '<div class="pqtext">' +
    getVectorLength(customerPreferences, vectorLength, zoom) +
    '</div>' +
    '</div>'
  );
};

/**
 *
 * @param projectId
 * @param reportId
 * @param productVersion
 * @param productLabel
 * @returns
 */
export const productTooltipLink = (
  projectId: number,
  reportId: string,
  productVersion: ProductVersion,
  productLabel: string,
) => {
  if (
    typeof projectId === 'undefined' ||
    projectId === null ||
    productVersion == null
  ) {
    return productLabel;
  } else {
    return (
      '<a class="productLink" href="/productSummary/' +
      projectId +
      '/' +
      reportId +
      '/' +
      productVersion.productId?.toFixed(0) +
      '/' +
      productVersion.version +
      '">' +
      productLabel +
      '</a>'
    );
  }
};
