import { useState } from 'react';
import * as React from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';
import { Autocomplete, Grid, LinearProgress, TextField } from '@mui/material';
import appToastAdd from 'actions/appToastAdd';
import MaterialButton from 'components/MaterialButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

interface PanelProduct {
  productId: number;
  productName: string;
}

interface SwapUserVariables {
  userId: number;
  panelId1: number;
  productId1: number;
  panelId2: number;
  productId2: number;
}

interface SwapUserProductReviewResult {
  swapUserReviews: { num_reviews_swapped: string };
}
interface Panel {
  id: number;
  name: string;
  pin: string;
}
// id: email
type UserMap = Map<number, string>;
type PanelistProduct = Map<number, PanelProduct[]>;

const swapMutation = gql`
  mutation swapUserReviews(
    $userId: Int!
    $panelId1: Int!
    $productId1: Int!
    $panelId2: Int!
    $productId2: Int!
  ) {
    swapUserReviews(
      input: {
        userId: $userId
        panelId1: $panelId1
        productId1: $productId1
        panelId2: $panelId2
        productId2: $productId2
      }
    ) {
      num_reviews_swapped
    }
  }
`;

const panelReviews = gql`
  query panelReviews($workspaceId: Int!) {
    allPanels(
      filter: {
        producerId: { equalTo: $workspaceId }
        and: { name: { notEqualTo: "", isNull: false }, pin: { isNull: false } }
      }
    ) {
      totalCount
      nodes {
        id
        name
        pin
        productReviewsByPanelId {
          nodes {
            userByUserId {
              id
              email
            }
            productByProductId {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const SwapUserReviews: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const producerId = useSelector((state) => selectWorkspaceProducerId(state));
  const [userId, setUserId] = useState<number>(null);
  const [userMap, setUserMap] = useState<UserMap>(new Map());
  const [productId1, setProductId1] = useState<number>(null);
  const [productId2, setProductId2] = useState<number>(null);
  const [panel1, setPanel1] = useState<Panel>(null);
  const [panel2, setPanel2] = useState<Panel>(null);

  const { data, loading } = useQuery(panelReviews, {
    variables: { workspaceId: producerId },
  });

  const [swapReviews, { data: swapData, loading: swapLoading }] = useMutation<
    SwapUserProductReviewResult,
    SwapUserVariables
  >(swapMutation, {
    onCompleted: (res) => {
      dispatch(
        appToastAdd({
          durationMilliseconds: 4000,
          title: 'Reviews swapped successfully',
          message: `${res.swapUserReviews.num_reviews_swapped} review(s) swapped`,
          toastKey: `toast_${Date.now()}`,
        }),
      );
      setUserId(null);
      setProductId1(null);
      setProductId2(null);
      setPanel1(null);
      setPanel2(null);
    },
    onError: (error) => {
      dispatch(
        appToastAdd({
          durationMilliseconds: 4000,
          title: 'Error swapping reviews',
          message: error.message,
          toastKey: `toast_${Date.now()}`,
        }),
      );
    },
  });

  const onPinChange = (panel: Panel, panelSelect: 'first' | 'second') => {
    if (!panel) return;
    if (panelSelect === 'first') {
      setPanel1(panel);
      data.allPanels.nodes.forEach((node) => {
        if (node.id === panel.id) {
          const tmpUserMap = new Map(userMap);
          node.productReviewsByPanelId.nodes.forEach((review) => {
            if (!review.userByUserId.email) return;
            tmpUserMap.set(review.userByUserId.id, review.userByUserId.email);
          });
          setUserMap(tmpUserMap);
        }
      });
    } else {
      setPanel2(panel);
    }
  };

  const getPanelledProducts = (panelId: number) => {
    const panelData = data?.allPanels?.nodes.find(
      (node) => node.id === panelId,
    );
    if (!panelData) return new Map();
    const productMap: PanelistProduct = new Map();
    panelData.productReviewsByPanelId.nodes.forEach((review) => {
      productMap.set(
        review.productByProductId.id,
        review.productByProductId.name,
      );
    });
    return productMap;
  };

  if (loading || !data) return <LinearProgress />;

  return (
    <Grid container spacing={2} direction="column">
      <Grid item xs={6}>
        <Autocomplete
          fullWidth
          value={
            panel1
              ? {
                  id: panel1.id,
                  pin: panel1.pin,
                  name: panel1.name,
                  label: `${panel1.name} (${panel1.pin})`,
                }
              : null
          }
          options={data.allPanels.nodes.map((node) => ({
            pin: node.pin,
            label: `${node.name} (${node.pin})`,
            id: node.id,
            name: node.name,
          }))}
          renderInput={(params) => (
            <TextField {...params} label="Panel 1 PIN" />
          )}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.pin === value.pin}
          filterOptions={(options, { inputValue }) =>
            options.filter((option) =>
              option.pin.toLowerCase().includes(inputValue.toLowerCase()),
            )
          }
          onChange={(_event, value) =>
            onPinChange(
              value
                ? {
                    id: value.id,
                    pin: value.pin,
                    name: value.name,
                  }
                : null,
              'first',
            )
          }
        />
      </Grid>
      <Grid item xs={6}>
        {panel1 && (
          <Autocomplete
            fullWidth
            value={userId ? { id: userId, label: userMap.get(userId) } : null}
            options={Array.from(userMap, ([id, email]) => ({
              id,
              label: email,
            }))}
            renderInput={(params) => <TextField {...params} label="User" />}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_event, value) => setUserId(value?.id ?? null)}
          />
        )}
      </Grid>

      <Grid item xs={6}>
        {panel1 && userId && (
          <Autocomplete
            fullWidth
            value={
              productId1
                ? {
                    id: productId1,
                    label: getPanelledProducts(panel1.id).get(productId1),
                  }
                : null
            }
            options={Array.from(
              getPanelledProducts(panel1.id),
              ([id, name]) => ({ id, label: name }),
            )}
            renderInput={(params) => (
              <TextField {...params} label="Panel 1 Product" />
            )}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_event, value) => setProductId1(value?.id ?? null)}
          />
        )}
      </Grid>

      <Grid item xs={6}>
        {panel1 && productId1 && (
          <Autocomplete
            fullWidth
            value={
              panel2
                ? {
                    id: panel2.id,
                    pin: panel2.pin,
                    name: panel2.name,
                    label: `${panel2.name} (${panel2.pin})`,
                  }
                : null
            }
            options={data.allPanels.nodes.map((node) => ({
              pin: node.pin,
              label: `${node.name} (${node.pin})`,
              id: node.id,
              name: node.name,
            }))}
            renderInput={(params) => (
              <TextField {...params} label="Panel 2 PIN" />
            )}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.pin === value.pin}
            filterOptions={(options, { inputValue }) =>
              options.filter((option) =>
                option.pin.toLowerCase().includes(inputValue.toLowerCase()),
              )
            }
            onChange={(_event, value) =>
              onPinChange(
                value
                  ? {
                      id: value.id,
                      pin: value.pin,
                      name: value.name,
                    }
                  : null,
                'second',
              )
            }
          />
        )}
      </Grid>
      <Grid item xs={6}>
        {panel2 && userId && (
          <Autocomplete
            fullWidth
            value={
              productId2
                ? {
                    id: productId2,
                    label: getPanelledProducts(panel2.id).get(productId2),
                  }
                : null
            }
            options={Array.from(
              getPanelledProducts(panel2.id),
              ([id, name]) => ({ id, label: name }),
            )}
            renderInput={(params) => (
              <TextField {...params} label="Panel 2 Product" />
            )}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_event, value) => setProductId2(value?.id ?? null)}
          />
        )}
      </Grid>

      <Grid item xs={4}>
        <MaterialButton
          soft
          teal
          variant="outlined"
          disabled={
            swapLoading ||
            !panel1 ||
            !userId ||
            !productId1 ||
            !panel2 ||
            !userId ||
            !productId2
          }
          onClick={() =>
            swapReviews({
              variables: {
                userId,
                panelId1: panel1.id,
                panelId2: panel2.id,
                productId1: productId1,
                productId2: productId2,
              },
            })
          }
        >
          {swapLoading ? 'Swapping...' : t('admin.swapUserReview')}
        </MaterialButton>
      </Grid>
    </Grid>
  );
};

export default SwapUserReviews;
