import { gql } from '@apollo/client';

export default gql`
  mutation DeleteNotificationMessageMutation(
    $input: DeleteNotificationByIdInput!
  ) {
    deleteNotificationById(input: $input) {
      notification {
        id
      }
    }
  }
`;
