import { useState } from 'react';
import * as React from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
  TextField,
  Autocomplete,
  Typography,
  LinearProgress,
} from '@mui/material';
import appToastAdd from 'actions/appToastAdd';
import MaterialButton from 'components/MaterialButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import selectWorkspaceProducerId from 'selectors/workspaceProducerId';

const panelReviews = gql`
  query panelReviews($pin: String!, $workspaceId: Int!) {
    allPanels(condition: { producerId: $workspaceId, pin: $pin }) {
      nodes {
        id
        pin
        productReviewsByPanelId {
          totalCount
          nodes {
            productByProductId {
              id
              name
            }
            userByUserId {
              id
              email
            }
          }
        }
      }
    }
  }
`;

const deleteMutation = gql`
  mutation deleteUserReview($userId: Int, $panelId: Int, $productId: Int) {
    deleteUserReview(
      input: { userId: $userId, panelId: $panelId, productId: $productId }
    ) {
      num_reviews_deleted
    }
  }
`;

interface PanelProduct {
  productId: number;
  productName: string;
}
// Map<userId, PanelProduct[]>
type PanelistProduct = Map<number, PanelProduct[]>;
type UserMap = Map<number, string>;
interface DeleteUserReviewResult {
  deleteUserReview: { num_reviews_deleted: number };
}

const DeleteUserReview: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const producerId = useSelector((state) => selectWorkspaceProducerId(state));
  const [userId, setUserId] = useState<number>(null);
  const [userMap, setUserMap] = useState<UserMap>(new Map());
  const [productId, setProductId] = useState<number>(null);
  const [panelId, setPanelId] = useState<number>(null);

  const [panelistProducts, setPanelistProducts] = useState<PanelistProduct>(
    new Map(),
  );

  const [
    getPanelReviews,
    { data: panelReviewsData, loading: loadingPanelReviews },
  ] = useLazyQuery(panelReviews, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!data.allPanels.nodes.length) {
        return;
      }
      const tmpPanelistProducts: PanelistProduct = new Map<
        number,
        PanelProduct[]
      >();
      const tmpUserMap: UserMap = new Map<number, string>();
      panelReviewsData.allPanels.nodes[0].productReviewsByPanelId.nodes.forEach(
        (node) => {
          if (!node.userByUserId.email || !node.productByProductId.name) {
            return;
          }
          const userID = node.userByUserId.id;
          if (!tmpPanelistProducts.has(userID)) {
            tmpUserMap.set(userID, node.userByUserId.email);
            tmpPanelistProducts.set(userID, [
              {
                productId: node.productByProductId.id,
                productName: node.productByProductId.name,
              },
            ]);
          } else {
            tmpPanelistProducts.get(userID).push({
              productId: node.productByProductId.id,
              productName: node.productByProductId.name,
            });
          }
        },
      );
      setPanelId(panelReviewsData.allPanels.nodes[0].id);
      setUserMap(tmpUserMap);
      setPanelistProducts(tmpPanelistProducts);
    },
  });

  const [
    removeReview,
    { loading: removeReviewLoading, error: removeReviewError },
  ] = useMutation<DeleteUserReviewResult>(deleteMutation, {
    onCompleted: (res) => {
      setUserId(null);
      setProductId(null);
      // Refetch the panel reviews to update the user list
      getPanelReviews({
        variables: {
          pin: panelReviewsData?.allPanels?.nodes[0].pin,
          workspaceId: producerId,
        },
      });
      dispatch(
        appToastAdd({
          durationMilliseconds: 4000,
          title: 'Review deleted successfully',
          message: `${res.deleteUserReview.num_reviews_deleted} review(s) deleted`,
          toastKey: `toast_${Date.now()}`,
        }),
      );
    },
    onError: (res) => {
      dispatch(
        appToastAdd({
          durationMilliseconds: 4000,
          title: 'Error deleting review',
          message: res.message,
          toastKey: `toast_${Date.now()}`,
        }),
      );
    },
  });

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '2em',
      }}
    >
      <TextField
        error={!panelReviewsData?.allPanels.nodes.length}
        helperText={
          !panelReviewsData?.allPanels.nodes.length && 'Enter a valid panel pin'
        }
        type="text"
        label="Panel Pin"
        onChange={(e) => {
          if (e.target.value.length !== 4) {
            return;
          }
          getPanelReviews({
            variables: {
              pin: e.target.value,
              workspaceId: producerId,
            },
          });
        }}
      />

      {loadingPanelReviews && <LinearProgress />}

      {userMap.size > 0 && (
        <Autocomplete
          fullWidth
          value={userId ? { id: userId, label: userMap.get(userId) } : null}
          options={Array.from(userMap, ([id, email]) => ({ id, label: email }))}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="Select User" variant="outlined" />
          )}
          onChange={(_event, newValue) => {
            setUserId(newValue?.id ?? null);
          }}
        />
      )}

      {userId && !panelistProducts.get(userId) && (
        <Typography>No reviews found for this user</Typography>
      )}

      {userId && panelistProducts.get(userId) && (
        <Autocomplete
          fullWidth
          options={panelistProducts.get(userId).map((product) => ({
            id: product.productId,
            label: product.productName,
          }))}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="Select Product" variant="outlined" />
          )}
          onChange={(_event, newValue) => {
            setProductId(newValue.id);
          }}
        />
      )}

      <MaterialButton
        soft
        teal
        variant="outlined"
        disabled={removeReviewLoading || !userId || !panelId || !productId}
        onClick={() => {
          removeReview({
            variables: {
              userId,
              panelId,
              productId,
            },
          });
        }}
      >
        {removeReviewLoading ? 'Deleting...' : t('admin.deleteUserReview')}
      </MaterialButton>
    </div>
  );
};

export default DeleteUserReview;
